import {
  put,
  call,
  select,
  takeLatest,
  takeEvery,
  all
} from 'redux-saga/effects'
import fileDownload from 'js-file-download'

import { Types } from '../actions/salepeople'
import { requestCreator, successCreator, failureCreator } from 'utils/actions'

import * as services from 'apis/salespeople'
import * as fieldRepServices from 'apis/fieldrep'

function * getSalespeople (action) {
  try {
    yield put(requestCreator(action.type))
    const { role, manufacturer } = yield select(_ => _.auth)
    let res = null
    if (role === 'manufacturer') {
      res = yield call(services.getSalespeople, action.limit, {
        status: action.status,
        LastEvaluatedKey: action.lastKey
      })
    } else {
      res = yield call(fieldRepServices.getSalespeople, action.limit, {
        status: action.status,
        LastEvaluatedKey: action.lastKey,
        manufacturerid: manufacturer.id
      })
    }

    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * createSalesperson (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.createSalesperson, action.payload)
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * updateSalesperson (action) {
  try {
    yield put(
      requestCreator(action.type, { id: action.id, payload: action.payload })
    )

    const { role, manufacturer } = yield select(_ => _.auth)
    if (role === 'manufacturer') {
      yield call(services.updateSalesperson, action.id, action.payload)
    } else {
      yield call(fieldRepServices.updateSalesperson, action.id, {
        ...action.payload,
        manufacturerid: manufacturer.id
      })
    }
    yield put(
      successCreator(action.type, {
        id: action.id,
        new: action.payload
      })
    )
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * deleteSalesperson (action) {
  try {
    yield put(requestCreator(action.type, { id: action.id }))
    const res = yield call(services.deleteSalesperson, action.id)
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * downloadSalespeople (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.downloadSalespeople)
    yield put(successCreator(action.type, res))
    fileDownload(res, 'salespeople.csv')
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

export default function * sagas () {
  yield all([
    takeLatest(Types.GET_SALESPEOPLE, getSalespeople),
    takeLatest(Types.CREATE_SALESPERSON, createSalesperson),
    takeEvery(Types.DELETE_SALESPERSON, deleteSalesperson),
    takeEvery(Types.UPDATE_SALESPERSON, updateSalesperson),
    takeEvery(Types.DOWNLOAD_SALESPEOPLE, downloadSalespeople)
  ])
}
