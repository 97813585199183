import { Auth } from 'aws-amplify'

export default async ({
  baseUrl,
  endpoint,
  method = 'get',
  body,
  paths,
  queries,
  auth = false
}) => {
  let url = `${baseUrl}/${endpoint}`
  if (paths) {
    Object.keys(paths).forEach(key => {
      url = url.replace(`{${key}}`, paths[key])
    })
  }

  if (queries) {
    url =
      url +
      '?' +
      Object.keys(queries).reduce(
        (result, key) =>
          result + (queries[key] ? `${key}=${queries[key]}&` : ''),
        ''
      )
  }

  let token
  if (auth) {
    const session = await Auth.currentSession()
    token = session.idToken.jwtToken
  }

  return fetch(url, {
    headers: { 'content-type': 'application/json', Authorization: token },
    method,
    body: JSON.stringify(body)
  })
    .then(response => {
      if (response.headers.get('content-type').includes('application/json')) {
        return response.json().then(content => ({ content, response }))
      } else {
        return response.text().then(content => ({ content, response }))
      }
    })
    .then(({ content, response }) => {
      if (!response.ok) return Promise.reject(content)
      else return content
    })
    .then(
      response => response,
      error => {
        throw error
      }
    )
}
