import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import createReducer, { history } from './reducers'
import sagas from './sagas'

function setupStore (initialState = {}) {
  const sagaMiddleware = createSagaMiddleware()

  // Middlewares and store enhancers
  const middlewares = [sagaMiddleware, routerMiddleware(history)]

  const enhancers = [applyMiddleware(...middlewares)]

  // add redux dev tool extension
  if (process.env.NODE_ENV === 'development') {
    // enable DevTools only when rendering during development.
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
    }
  }

  const store = createStore(
    createReducer(),
    initialState,
    compose(...enhancers)
  )

  // extensions
  store.runSaga = sagaMiddleware.run
  store.history = history

  // run sagas
  sagas.forEach(saga => store.runSaga(saga))

  return store
}

export const store = setupStore()

export default setupStore
