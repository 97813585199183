import { put, call, takeLatest, all } from 'redux-saga/effects'
import { Types } from 'store/actions/admin'
import * as services from 'apis/admin'
import { requestCreator, successCreator, failureCreator } from 'utils/actions'

function * getUsers (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.getUsers)
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * createUser (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.createUser, action.payload)
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * enableUser (action) {
  try {
    yield put(requestCreator(action.type, { email: action.email }))
    const res = yield call(services.enableUser, { email: action.email })
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * disableUser (action) {
  try {
    yield put(requestCreator(action.type, { email: action.email }))
    const res = yield call(services.disableUser, { email: action.email })
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * dismissUser (action) {
  try {
    yield put(requestCreator(action.type, { email: action.email }))
    const res = yield call(services.dismissUser, { email: action.email })
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

export default function * sagas () {
  yield all([
    takeLatest(Types.GET_USERS, getUsers),
    takeLatest(Types.CREATE_USER, createUser),
    takeLatest(Types.ENABLE_USER, enableUser),
    takeLatest(Types.DISABLE_USER, disableUser),
    takeLatest(Types.DISMISS_USER, dismissUser)
  ])
}
