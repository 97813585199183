import { createReducer } from 'reduxsauce'
import { Types } from '../actions/claim'
import { success } from 'utils/actions'
import mergeArray from 'utils/mergeArray'

// Initial State
const initialState = {
  claims: {
    items: [],
    lastKey: {
      submitted: null,
      approved_denied: null,
      invoiced: null
    }
  },
  invoices: {
    items: [],
    next: null
  }
}

const resetClaims = (state, action) => {
  return {
    ...state,
    claims: {
      ...state.claims,
      lastKey: {
        ...state.claims.lastKey,
        [action.status]: null
      },
      items: state.claims.items.filter(item =>
        action.status === 'approved_denied'
          ? item.status !== 'approved' && item.status !== 'denied'
          : item.status !== action.status
      )
    }
  }
}

const resetInvoices = (state, action) => {
  return {
    ...state,
    invoices: initialState.invoices
  }
}
const getClaimsSuccess = (state, action) => {
  return {
    ...state,
    claims: {
      items: mergeArray(state.claims.items, action.payload.Items, 'id'),
      lastKey: {
        ...state.claims.lastKey,
        [action.payload.status]: action.payload.LastEvaluatedKey
      }
    }
  }
}

const updateClaimSuccess = (state, action) => {
  return {
    ...state,
    claims: {
      ...state.claims,
      items: state.claims.items.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload.new
          }
        } else {
          return item
        }
      })
    }
  }
}

const getInvoicesSuccess = (state, action) => {
  return {
    ...state,
    invoices: {
      items: [...state.invoices.items, ...action.payload.Items],
      next: action.payload.Next
    }
  }
}

const generateInvoicesSuccess = (state, action) => {
  return {
    ...state,
    claims: {
      ...state.claims,
      items: state.claims.items.filter(item => {
        return !action.payload.ids.includes(item.id)
      })
    }
  }
}

// Map action types to reducer functions
export const handlers = {
  [Types.RESET_CLAIMS]: resetClaims,
  [Types.RESET_INVOICES]: resetInvoices,
  [success(Types.GET_CLAIMS)]: getClaimsSuccess,
  [success(Types.UPDATE_CLAIM)]: updateClaimSuccess,
  [success(Types.GET_INVOICES)]: getInvoicesSuccess,
  [success(Types.GENERATE_INVOICES)]: generateInvoicesSuccess
}

// Export Reducer
export default createReducer(initialState, handlers)
