import invokeApi from 'utils/invokeApi'
import { api } from 'configs'

export const submit = payload => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.feedback.submit,
    method: 'post',
    body: payload,
    auth: true
  })
}
