import invokeApi from 'utils/invokeApi'
import { api } from 'configs'

export const getProducts = (limit, lastKey) => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.products.list,
    method: 'post',
    body: { LastEvaluatedKey: lastKey },
    queries: { limit },
    auth: true
  })
}

export const createProduct = payload => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.products.create,
    method: 'post',
    body: payload,
    auth: true
  })
}

export const updateProduct = (id, payload) => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.products.update,
    method: 'put',
    body: payload,
    paths: { id },
    auth: true
  })
}

export const deleteProduct = id => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.products.delete,
    method: 'delete',
    paths: { id },
    auth: true
  })
}

export const downloadProducts = () => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.products.download,
    method: 'get',
    auth: true
  })
}

export const uploadProducts = payload => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.products.upload,
    method: 'put',
    body: payload,
    auth: true
  })
}
