import { Storage } from 'aws-amplify'
import uuidv1 from 'uuid/v1'

// export function deleteFile(file) {
//   const userPool = new CognitoUserPool(aws.userPool)
//   const cognitoUser = userPool.getCurrentUser()

//   return new Promise((resolve, reject) =>
//     cognitoUser.getSession((err, session) => {
//       if (err) return reject(err)

//       AWS.config.region = aws.region

//       AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//           IdentityPoolId : aws.identityPoolId, // your identity pool id here
//           Logins : {
//               // Change the key below according to the specific region your user pool is in.
//               [`cognito-idp.${ aws.region }.amazonaws.com/${ aws.userPool.UserPoolId }`] : session.getIdToken().getJwtToken()
//           }
//       })

//       AWS.config.credentials.clearCachedId()

//       AWS.config.credentials.refresh((error) => {
//         if (error) {
//           console.error(error)
//         } else {
//           var s3 = new AWS.S3({
//             apiVersion: cognitoUser.pool.client.config.apiVersion,
//             params: { Bucket: aws.s3.bucketName }
//           })

//           const key = file

//           s3.deleteObject({ Key: key }, (err, data) => {
//             if (err) reject(err)
//             else resolve(data)
//           })
//         }
//       })

//     })
//   ).then(response => {
//       return response
//     }, err => {
//       throw err
//     }
//   )

// }

export function uploadFile (folder, file) {
  const key = folder + '/' + uuidv1() + '.csv'

  return Storage.put(key, file, {
    customPrefix: {
      public: ''
    },
    contentType: file.type
  })
}
