import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    getCurrentUser: null,
    getManufacturer: null,
    createManufacturer: ['companyData', 'userData'],
    updateManufacturer: ['payload'],
    signIn: ['username', 'password'],
    completeNewPassword: ['newPassword'],
    forgotPassword: ['username'],
    forgotPasswordSubmit: ['username', 'code', 'newPassword'],
    confirmSignUp: ['username', 'code'],
    resendSignUp: ['username'],
    fieldRepSignIn: ['key'],
    signOut: null
  },
  { prefix: 'auth/' }
)

export { Types, Creators }
