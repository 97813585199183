import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    getUsers: null,
    enableUser: ['email'],
    disableUser: ['email'],
    dismissUser: ['email'],
    createUser: ['payload']
  },
  { prefix: 'admin/' }
)

export { Types, Creators }
