import React from 'react'
import { Button, notification } from 'antd'
import { put, call, select, takeLatest, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { Types, Creators } from '../actions/auth'
import { requestCreator, successCreator, failureCreator } from 'utils/actions'

import * as services from 'apis/auth'
import { app } from 'configs'

function * getCurrentUser (action) {
  try {
    yield put(requestCreator(action.type, {}))

    let manufacturer = {
      id: localStorage.getItem(`${app.prefix}_MANUFACTURERID`)
    }

    let role = 'none'
    let user

    if (manufacturer.id) {
      role = 'fieldrep'
      user = {
        attributes: {}
      }
    } else {
      role = 'manufacturer'
      user = yield call(services.getCurrentUserInfo)
      if (!user) throw new Error('Not authenticated')

      manufacturer = yield call(services.getManufacturer)
    }

    // // One Signal
    // if (mode === 'Sales') {
    //   OneSignal.sendTags({
    //     email: user.attributes.email,
    //     userId: user.attributes.sub
    //   })
    // }

    yield put(
      successCreator(action.type, {
        authenticated: true,
        user,
        role,
        manufacturer
      })
    )

    // Set Document Title
    if (manufacturer.name) {
      document.title = manufacturer.name
    }
  } catch (err) {
    yield put(failureCreator(action.type, { err, skipAlert: true }))
  }
}

function * getManufacturer (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.getManufacturer)
    yield put(successCreator(action.type, { manufacturer: res }))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * createManufacturer (action) {
  try {
    yield put(requestCreator(action.type))

    let res = yield call(services.createManufacturer, {
      ...action.companyData,
      admin: action.userData.email
    })

    res = yield call(services.signUp, {
      ...action.userData,
      'custom:manufacturerid': res.id
    })
    yield put(successCreator(action.type, { manufacturer: res }))

    if (app.showNotificationInSaga) {
      notification['success']({
        message: 'Success',
        description:
          'Your company has been created and it is pending Administrator approval.',
        duration: 3
      })
    }

    if (res.userConfirmed) {
      yield put(push('/'))
    } else {
      yield put(push(`/user/confirm/${res.user.username}`))
    }
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * updateManufacturer (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.updateManufacturer, action.payload)
    yield put(successCreator(action.type, { manufacturer: res.new }))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function confirmUserMessage () {
  return new Promise((resolve, reject) => {
    const key = `open${Date.now()}`
    let btn = (
      <Button
        type='primary'
        size='small'
        onClick={() => {
          notification.close(key)
          resolve('toConfirmUser')
        }}
      >
        Confirm User
      </Button>
    )
    notification['error']({
      message: 'Error...',
      description:
        'User is not confirmed. If you want to confirm user now, please click the button below.',
      duration: 3,
      btn,
      key,
      onClose: () => {
        reject('close')
      }
    })
  })
}

function * signIn (action) {
  try {
    yield put(requestCreator(action.type))
    const user = yield call(services.signIn, action.username, action.password)

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put(
        successCreator(action.type, { authenticated: false, res: user })
      )
      yield put(push('/user/password/new'))
    } else if (
      ['SMS_MFA', 'SOFTWARE_TOKEN_MFA', 'MFA_SETUP'].includes(
        user.challengeName
      )
    ) {
      // impossible
    } else {
      if (
        !user.attributes['custom:manufacturerid'] ||
        user.attributes['custom:manufacturerid'] === 'UNKNOWN'
      ) {
        yield put(Creators.signOut())
        yield put(
          failureCreator(action.type, {
            err: {
              message:
                'Your account is not approved yet. \n Please contact us to activate your account.',
              title: 'Not Approved',
              duration: 0
            }
          })
        )
      } else {
        const manufacturer = yield call(services.getManufacturer)
        yield put(
          successCreator(action.type, {
            authenticated: true,
            user,
            role: 'manufacturer',
            manufacturer
          })
        )
      }
    }
  } catch (err) {
    if (err.code === 'UserNotConfirmedException') {
      yield put(failureCreator(action.type, { err, skipAlert: true }))

      if (app.showNotificationInSaga) {
        const res = yield call(confirmUserMessage)
        if (res === 'toConfirmUser') {
          yield put(Creators.resendSignUp(action.username))
          yield put(push(`/user/confirm/${action.username}`))
        }
      }
    } else {
      yield put(failureCreator(action.type, { err }))
    }
  }
}

function * completeNewPassword (action) {
  try {
    yield put(requestCreator(action.type, {}))
    const user = yield select(_ => _.auth.res)
    const res = yield call(
      services.completeNewPassword,
      user,
      action.newPassword
    )
    yield put(successCreator(action.type, { res }))

    if (app.showNotificationInSaga) {
      notification['success']({
        message: 'New Password',
        description: 'Password is reset.',
        duration: 3
      })
    }

    yield put(push('/user/signin'))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * forgotPassword (action) {
  try {
    yield put(requestCreator(action.type, {}))
    const res = yield call(services.forgotPassword, action.username)
    yield put(successCreator(action.type, { res }))

    if (app.showNotificationInSaga) {
      notification['success']({
        message: 'Verification Code is sent',
        description:
          'Verification Code is sent to your email. Please check your email.',
        duration: 3
      })
    }

    yield put(push(`/user/password/confirm/${action.username}`))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * forgotPasswordSubmit (action) {
  try {
    yield put(requestCreator(action.type, {}))
    const res = yield call(
      services.forgotPasswordSubmit,
      action.username,
      action.code,
      action.newPassword
    )
    yield put(successCreator(action.type, { res }))

    if (app.showNotificationInSaga) {
      notification['success']({
        message: 'Password Reset',
        description: 'New Password is set successfully !',
        duration: 3
      })
    }

    yield put(push('/user/signin'))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * confirmSignUp (action) {
  try {
    yield put(requestCreator(action.type, {}))
    const res = yield call(services.confirmSignUp, action.username, action.code)
    yield put(successCreator(action.type, { res }))

    if (app.showNotificationInSaga) {
      notification['success']({
        message: 'User Confirmed',
        description: 'Thank you! Your account has been confirmed.',
        duration: 0
      })
    }

    yield put(push('/user/signin'))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * resendSignUp (action) {
  try {
    yield put(requestCreator(action.type, {}))
    const res = yield call(services.resendSignUp, action.username)
    yield put(successCreator(action.type, { res }))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * signOut (action) {
  try {
    yield put(requestCreator(action.type))

    localStorage.removeItem(`${app.prefix}_MANUFACTURERID`)

    // Set Document Title
    document.title = app.appName

    yield call(services.signOut)
    yield put({ type: 'CLEAR' })

    yield put(successCreator(action.type))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * fieldRepSignIn (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.fieldRepSignIn, action.key)
    localStorage.setItem(`${app.prefix}_MANUFACTURERID`, res.manufacturerid)
    yield put(
      successCreator(action.type, {
        authenticated: true,
        role: 'fieldrep',
        manufacturer: {
          id: res.manufacturerid
        }
      })
    )
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

export default function * sagas () {
  yield all([
    takeLatest(Types.GET_CURRENT_USER, getCurrentUser),
    takeLatest(Types.GET_MANUFACTURER, getManufacturer),
    takeLatest(Types.CREATE_MANUFACTURER, createManufacturer),
    takeLatest(Types.UPDATE_MANUFACTURER, updateManufacturer),
    takeLatest(Types.COMPLETE_NEW_PASSWORD, completeNewPassword),
    takeLatest(Types.FORGOT_PASSWORD, forgotPassword),
    takeLatest(Types.FORGOT_PASSWORD_SUBMIT, forgotPasswordSubmit),
    takeLatest(Types.CONFIRM_SIGN_UP, confirmSignUp),
    takeLatest(Types.RESEND_SIGN_UP, resendSignUp),
    takeLatest(Types.SIGN_IN, signIn),
    takeLatest(Types.FIELD_REP_SIGN_IN, fieldRepSignIn),
    takeLatest(Types.SIGN_OUT, signOut)
  ])
}
