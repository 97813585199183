import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    getAnalytics: null
  },
  { prefix: 'analytics/' }
)

export { Types, Creators }
