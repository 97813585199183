import { createReducer } from 'reduxsauce'
import { Types } from 'store/actions/auth'
import { success, failure } from 'utils/actions'

export const initialState = {
  authenticated: undefined,
  user: {
    attributes: {}
  },
  manufacturer: {},
  role: 'none',
  res: {}
}

const commonSuccess = (state, action) => ({
  ...state,
  ...action.payload
})

const getCurrentUserFailure = (state, action) => ({
  ...state,
  authenticated: false,
  user: {
    attributes: {}
  },
  role: 'none'
})

const signOutSuccess = (state, action) => initialState

const handlers = {
  [success(Types.GET_CURRENT_USER)]: commonSuccess,
  [failure(Types.GET_CURRENT_USER)]: getCurrentUserFailure,
  [success(Types.GET_MANUFACTURER)]: commonSuccess,
  [success(Types.UPDATE_MANUFACTURER)]: commonSuccess,
  [success(Types.SIGN_IN)]: commonSuccess,
  [success(Types.FIELD_REP_SIGN_IN)]: commonSuccess,
  [success(Types.SIGN_OUT)]: signOutSuccess
}

export default createReducer(initialState, handlers)
