import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import auth from './auth'
import product from './product'
import salespeople from './salespeople'
import claim from './claim'
import global from './global'
import admin from './admin'
import analytics from './analytics'
import feedback from './feedback'

export const history = createBrowserHistory()

const reducers = {
  router: connectRouter(history),
  global,
  auth,
  product,
  salespeople,
  claim,
  admin,
  analytics,
  feedback
}

export default function createReducer () {
  const appReducer = combineReducers(reducers)

  // reset redux state when action type is 'CLEAR'
  const rootReducer = (state, action) => {
    if (action.type === 'CLEAR') {
      state = undefined
    }

    return appReducer(state, action)
  }

  return rootReducer
}
