import { Auth } from 'aws-amplify'

import { api } from 'configs'
import invokeApi from 'utils/invokeApi'

export function getCurrentUserInfo () {
  return Auth.currentUserInfo()
}

export function getManufacturer () {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.manufacturers.get,
    method: 'get',
    auth: true
  })
}

export function createManufacturer (payload) {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.manufacturers.create,
    method: 'post',
    body: payload
  })
}

export function updateManufacturer (payload) {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.manufacturers.update,
    method: 'put',
    body: payload,
    auth: true
  })
}

export function signIn (username, password) {
  return Auth.signIn(username, password)
}

export function signUp (payload) {
  const fields = [
    'custom:manufacturerid',
    'custom:jobtitle',
    'custom:company',
    'given_name',
    'family_name',
    'phone_number',
    'email'
  ]
  const attributes = {}
  fields.forEach(e => {
    if (payload[e] != null) {
      attributes[e] = payload[e]
    }
  })

  return Auth.signUp({
    username: payload.email,
    password: payload.password,
    attributes
  })
}

export function completeNewPassword (username, newPassword) {
  return Auth.completeNewPassword(username, newPassword)
}

export function forgotPassword (username) {
  return Auth.forgotPassword(username)
}

export function forgotPasswordSubmit (username, code, newPassword) {
  return Auth.forgotPasswordSubmit(username, code, newPassword)
}

export function confirmSignUp (username, code) {
  return Auth.confirmSignUp(username, code)
}

export function resendSignUp (username) {
  return Auth.resendSignUp(username)
}

export const fieldRepSignIn = key => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.field.login,
    method: 'post',
    body: { fieldrepaccesskey: key }
  })
}

export function signOut () {
  return Auth.signOut()
}
