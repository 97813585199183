import invokeApi from 'utils/invokeApi'
import { api } from 'configs'

export const getClaims = (limit, payload) => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.claims.list,
    method: 'post',
    body: payload,
    queries: { limit },
    auth: true
  })
}

export const updateClaim = (id, payload) => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.claims.update,
    method: 'put',
    body: payload,
    paths: { id },
    auth: true
  })
}

export const generateInvoices = payload => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.claims.generateInvoices,
    method: 'put',
    body: payload,
    auth: true
  })
}

export const getInvoices = next => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.claims.invoices,
    method: 'get',
    queries: { Next: next },
    auth: true
  })
}
