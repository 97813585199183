const API_BASE_URL =
  'https://nd6n0x97ij.execute-api.us-east-1.amazonaws.com/prod'

export const aws = {
  auth: {
    region: 'us-east-1',
    identityPoolId: 'us-east-1:ce67a369-bc99-4e1f-a22c-10586dfad187',
    userPoolId: 'us-east-1_127vGU63o',
    userPoolWebClientId: '7m85liorj0rli52jv62hmkodot'
  },
  s3: {
    region: 'us-east-1',
    bucket: 'prod-media-spiffme-com',
    receipts: 'receipts',
    products: 'products'
  },
  appSync: {
    graphqlEndpoint:
      'https://jhjkxnukanef7p5cshyhl3fleq.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    authenticationType: 'API_KEY',
    apiKey: 'da2-lawndhogovfqbn6p4u4ipxwl7m'
  }
}

export const api = {
  base: API_BASE_URL,
  products: {
    list: 'products', // [POST] ?limit
    create: 'products/create', // [POST]
    update: 'products/{id}', // [PUT]
    delete: 'products/{id}', // [DELETE]
    download: 'products', // [GET]
    upload: 'products' // [PUT]
  },
  users: {
    list: 'users', // [GET]
    enable: 'users/enable', // [PUT]
    disable: 'users/enable', // [PUT]
    dismiss: 'users/dismiss', // [PUT]
    create: 'users' // [POST]
  },
  claims: {
    list: 'claims', // [POST] ?limit
    update: 'claims/{id}', // [PUT]
    generateInvoices: 'claims/invoice', // [PUT]
    invoices: 'invoices' // [GET] ?Next
  },
  salespeople: {
    list: 'salespeople', // [POST] ?limit
    create: 'salespeople/create', // [POST]
    update: 'salespeople/{id}', // [PUT]
    delete: 'salespeople/{id}', // [DELETE]
    download: 'salespeople/download' // [GET]
  },
  manufacturers: {
    get: 'manufacturers', // [GET]
    update: 'manufacturers', // [PUT]
    create: 'manufacturers' // [POST]
  },
  feedback: {
    submit: 'feedback' // [POST]
  },
  analytics: {
    get: 'analytics'
  },
  field: {
    login: 'field/login', // [POST]
    salespeople: {
      list: 'field/salespeople', // [POST] ?limit
      create: 'field/salespeople/create', // [POST]
      update: 'field/salespeople/{id}' // [PUT]
    }
  }
}

export const app = {
  showNotificationInSaga: true,
  alertUncaughted: true,
  alertFailure: true,
  fetchLimit: 50,
  prefix: 'SPIFFME',
  appName: 'Spiff Me'
}
