import { put, call, takeLatest, all } from 'redux-saga/effects'
import { Types } from '../actions/product'
import { requestCreator, successCreator, failureCreator } from 'utils/actions'
import fileDownload from 'js-file-download'

import * as services from 'apis/product'
import { aws } from 'configs'
import { uploadFile } from 'apis/assets'

function * getProducts (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.getProducts, action.limit, action.lastKey)
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * createProduct (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.createProduct, action.payload)
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * updateProduct (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.updateProduct, action.id, action.payload)
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * deleteProduct (action) {
  try {
    yield put(requestCreator(action.type, { id: action.id }))
    const res = yield call(services.deleteProduct, action.id)
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * downloadProducts (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.downloadProducts)
    yield put(successCreator(action.type, res))
    fileDownload(res, 'products.csv')
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * uploadProducts (action) {
  try {
    yield put(requestCreator(action.type))
    const uploaded = yield call(uploadFile, aws.s3.products, action.file)
    const res = yield call(services.uploadProducts, { filename: uploaded.key })
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err, skipAlert: true }))
  }
}

export default function * sagas () {
  yield all([
    takeLatest(Types.GET_PRODUCTS, getProducts),
    takeLatest(Types.CREATE_PRODUCT, createProduct),
    takeLatest(Types.UPDATE_PRODUCT, updateProduct),
    takeLatest(Types.DELETE_PRODUCT, deleteProduct),
    takeLatest(Types.DOWNLOAD_PRODUCTS, downloadProducts),
    takeLatest(Types.UPLOAD_PRODUCTS, uploadProducts)
  ])
}
