import { notification } from 'antd'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import { Types } from 'store/actions/feedback'
import * as services from 'apis/feedback'
import { requestCreator, successCreator, failureCreator } from 'utils/actions'
import { app } from 'configs'

function * submitFeedback (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.submit, action.payload)
    yield put(successCreator(action.type, res))

    if (app.showNotificationInSaga) {
      notification['success']({
        message: 'Thank you',
        description: 'Thank you for submitting your feedback.',
        duration: 3
      })
    }
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

export default function * sagas () {
  yield all([takeLatest(Types.SUBMIT_FEEDBACK, submitFeedback)])
}
