import { put, call, takeLatest, all } from 'redux-saga/effects'
import { Types } from 'store/actions/analytics'
import { requestCreator, successCreator, failureCreator } from 'utils/actions'
import * as services from 'apis/analytics'

function * getAnalytics (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.getAnalytics)
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

export default function * sagas () {
  yield all([takeLatest(Types.GET_ANALYTICS, getAnalytics)])
}
