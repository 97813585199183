import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    getSalespeople: ['limit', 'lastKey'],
    createSalesperson: ['payload'],
    updateSalesperson: ['id', 'payload'],
    deleteSalesperson: ['id'],
    downloadSalespeople: null
  },
  { prefix: 'salepeople/' }
)

export { Types, Creators }
