import { put, select, takeEvery, all } from 'redux-saga/effects'
import { Creators } from 'store/actions/global'
import { originalType } from 'utils/actions'
import { app } from 'configs'
import { notification } from 'antd'
import { errorMessage } from 'utils/error'

function * listenAction (action) {
  try {
    // console.log(action)
    const { status } = yield select(state => 'global')
    let newStatus
    if (action.type.endsWith('/request')) {
      newStatus = {
        ...status,
        [originalType(action.type)]: 'request'
      }
      yield put(Creators.updateStates({ status: newStatus }))
    } else if (action.type.endsWith('/success')) {
      newStatus = {
        ...status,
        [originalType(action.type)]: 'success'
      }
      yield put(Creators.updateStates({ status: newStatus }))
    } else if (action.type.endsWith('/failure')) {
      newStatus = {
        ...status,
        [originalType(action.type)]: 'failure'
      }
      yield put(Creators.updateStates({ status: newStatus }))

      if (app.alertFailure) {
        const { err, skipAlert, duration } = action.payload
        if (err && skipAlert !== true) {
          notification['error']({
            message: err.title || 'Error',
            description: errorMessage(err.errorMessage || err.message),
            duration: duration !== undefined ? duration : 4
          })
        }
      }
    }
  } catch (err) {
    if (app.alertUncaughted) {
      notification['error']({
        message: 'Error',
        description: errorMessage(err.errorMessage || err.message),
        duration: 4
      })
    }
  }
}

export function * globalSaga () {
  yield all([takeEvery('*', listenAction)])
}
