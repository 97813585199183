import { createReducer } from 'reduxsauce'
import { Types } from 'store/actions/admin'
import { request, success } from 'utils/actions'

export const initialState = {
  items: []
}

const getUsersSuccess = (state, action) => {
  return {
    ...state,
    items: action.payload.Items
  }
}

export const createUserSuccess = (state, action) => {
  const newUser = action.payload.User.Attributes
  return {
    ...state,
    items: [
      {
        manufacturerid: newUser.manufacturerid,
        userid: newUser.sub,
        name: newUser.name,
        email: newUser.email,
        given_name: newUser.given_name,
        family_name: newUser.family_name,
        phone_number: newUser.phone_number,
        jobtitle: newUser.jobtitle,
        status: action.payload.User.Enabled ? 'enabled' : 'disabled'
      },
      ...state.items
    ]
  }
}

const enableUserSuccess = (state, action) => {
  return {
    ...state,
    items: state.items.map(e => {
      if (e.email === action.payload.email) {
        return {
          ...e,
          status: 'enabled'
        }
      } else {
        return e
      }
    })
  }
}

const disableUserSuccess = (state, action) => {
  return {
    ...state,
    items: state.items.map(e => {
      if (e.email === action.payload.email) {
        return {
          ...e,
          status: 'disabled'
        }
      } else {
        return e
      }
    })
  }
}

const dismissUserSuccess = (state, action) => {
  return {
    ...state,
    items: state.items.filter(item => item.email !== action.payload.email)
  }
}

const handlers = {
  [success(Types.GET_USERS)]: getUsersSuccess,
  [success(Types.CREATE_USER)]: createUserSuccess,
  [request(Types.ENABLE_USER)]: enableUserSuccess,
  [request(Types.DISABLE_USER)]: disableUserSuccess,
  [request(Types.DISMISS_USER)]: dismissUserSuccess
}

export default createReducer(initialState, handlers)
