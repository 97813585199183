import { createReducer } from 'reduxsauce'
import { Types } from '../actions/salepeople'
import { request, success } from 'utils/actions'

export const initialState = {
  items: [],
  dealings: [],
  lastKey: null
}

export const getSalespeopleSuccess = (state, action) => {
  return {
    ...state,
    items: [...state.items, ...action.payload.Items],
    lastKey: action.payload.LastEvaluatedKey
  }
}

export const createSalespersonSuccess = (state, action) => {
  return { ...state, items: [action.payload.new, ...state.items] }
}

export const updateSalespersonRequest = (state, action) => {
  const dealings = [
    { id: action.payload.id, status: action.payload.payload.status },
    ...state.dealings
  ]
  return { ...state, dealings }
}

export const updateSalespersonSuccess = (state, action) => {
  const items = state.items.map(item => {
    if (item.salespersonid === action.payload.id) {
      return {
        ...item,
        ...action.payload.new
      }
    } else {
      return item
    }
  })

  const dealings = state.dealings.filter(item => item.id !== action.payload.id)
  return { ...state, items, dealings }
}

export const deleteSalespersonRequest = (state, action) => {
  const dealings = [{ id: action.payload.id }, ...state.dealings]
  return { ...state, dealings }
}

export const deleteSalespersonSuccess = (state, action) => {
  const items = state.items.filter(
    item => item.salespersonid !== action.payload.id
  )
  const dealings = state.dealings.filter(item => item.id !== action.payload.id)
  return { ...state, items, dealings }
}

export const handlers = {
  [success(Types.GET_SALESPEOPLE)]: getSalespeopleSuccess,
  [success(Types.CREATE_SALESPERSON)]: createSalespersonSuccess,
  [request(Types.UPDATE_SALESPERSON)]: updateSalespersonRequest,
  [success(Types.UPDATE_SALESPERSON)]: updateSalespersonSuccess,
  [request(Types.DELETE_SALESPERSON)]: deleteSalespersonRequest,
  [success(Types.DELETE_SALESPERSON)]: deleteSalespersonSuccess
}

export default createReducer(initialState, handlers)
