import { globalSaga } from './global'
import authSaga from './auth'
import productSaga from './product'
import salespeopleSaga from './salespeople'
import claimSaga from './claim'
import adminSaga from './admin'
import analyticsSaga from './analytics'
import feedbackSaga from './feedback'

const sagas = [
  globalSaga,
  authSaga,
  claimSaga,
  productSaga,
  salespeopleSaga,
  adminSaga,
  analyticsSaga,
  feedbackSaga
]

export default sagas
