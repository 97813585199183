import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import ReactResizeDetector from 'react-resize-detector'

import { Types } from 'store/actions/auth'

const AsyncLanding = React.lazy(() => import('modules/Landing'))
const AsyncMain = React.lazy(() => import('modules/Main'))
const AsyncTerms = React.lazy(() => import('modules/Terms'))
const AsyncPolicy = React.lazy(() => import('modules/Policy'))

class App extends Component {
  state = {
    isLoading: true
  }

  componentDidMount () {
    this.props.getCurrentUser()
  }

  componentDidUpdate ({ global: prevGlobal }) {
    const { global } = this.props

    if (
      prevGlobal.status[Types.GET_CURRENT_USER] !==
        global.status[Types.GET_CURRENT_USER] &&
      global.status[Types.GET_CURRENT_USER] !== 'request'
    ) {
      this.setState({ isLoading: false })
    }
  }

  onResize = width => {
    const { global, setDisplayMode } = this.props

    if (width < 640 && global.displayMode === 'Table') {
      setDisplayMode('List/Detail')
    }

    if (width >= 640 && global.displayMode !== 'Table') {
      setDisplayMode('Table')
    }
  }

  render () {
    if (this.state.isLoading) return null
    const { auth } = this.props
    return (
      <React.Suspense fallback={<div />}>
        <div>
          <Switch>
            {auth.authenticated && (
              <Route path='/edit/' component={AsyncMain} />
            )}
            {auth.authenticated && <Redirect to='/edit' />}
            <Route path='/help/terms' component={AsyncTerms} />
            <Route path='/help/policy' component={AsyncPolicy} />
            <Route path='/' component={AsyncLanding} />
          </Switch>

          <ReactResizeDetector handleWidth onResize={this.onResize} />
        </div>
      </React.Suspense>
    )
  }
}

export default App
