import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route } from 'react-router-dom'
import Amplify from 'aws-amplify'
import { ConfigProvider } from 'antd'
import enUS from 'antd/es/locale/en_US'
import { ApolloProvider } from 'react-apollo'
import { Rehydrated } from 'aws-appsync-react'
import AWSAppSyncClient from 'aws-appsync'

import { store } from 'store/setup'
import { aws } from 'configs'
import Core from 'modules/App'
import './styles/styles.css'

Amplify.configure({ Auth: aws.auth, Storage: aws.s3 })

const { appSync } = aws

const client = new AWSAppSyncClient(
  {
    url: appSync.graphqlEndpoint,
    region: appSync.region,
    auth: {
      type: appSync.authenticationType,
      apiKey: appSync.apiKey
    }
    // disableOffline: true,
  },
  {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore'
    },
    mutate: {
      errorPolicy: 'ignore'
    }
  }
)

function App () {
  return (
    <Provider store={store}>
      <ConnectedRouter history={store.history}>
        <ApolloProvider client={client}>
          <Rehydrated>
            <ConfigProvider locale={enUS}>
              <Route path='/' component={Core} />
            </ConfigProvider>
          </Rehydrated>
        </ApolloProvider>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
