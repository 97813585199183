import { createReducer } from 'reduxsauce'
import { Types } from '../actions/global'

export const initialState = {
  status: {},
  displayMode: 'Table'
}

const updateStates = (state, action) => ({
  ...state,
  ...action.payload
})

const setDisplayMode = (state, action) => ({
  ...state,
  displayMode: action.mode
})

const handlers = {
  [Types.UPDATE_STATES]: updateStates,
  [Types.SET_DISPLAY_MODE]: setDisplayMode
}

export default createReducer(initialState, handlers)
