import invokeApi from 'utils/invokeApi'
import { api } from 'configs'

export const getSalespeople = (limit, payload) => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.field.salespeople.list,
    method: 'post',
    body: payload,
    queries: { limit }
  })
}

export const createSalesperson = payload => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.field.salespeople.create,
    method: 'post',
    body: payload
  })
}

export const updateSalesperson = (id, payload) => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.field.salespeople.update,
    method: 'put',
    body: { ...payload, salespersonid: id }
  })
}
