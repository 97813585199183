import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    submitFeedback: ['payload']
  },
  { prefix: 'feedback/' }
)

export { Types, Creators }
