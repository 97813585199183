import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    getProducts: ['limit', 'lastKey'],
    createProduct: ['payload'],
    updateProduct: ['id', 'payload'],
    deleteProduct: ['id'],
    downloadProducts: null,
    uploadProducts: ['file']
  },
  { prefix: 'product/' }
)

export { Types, Creators }
