export default function (a1, a2, id) {
  var len1 = a1.length

  for (var x = 0; x < a2.length; x++) {
    var found = false

    for (var y = 0; y < len1; y++) {
      if (a2[x][id] === a1[y][id]) {
        found = true
        break
      }
    }

    if (!found) {
      a1.push(a2.splice(x--, 1)[0])
    }
  }

  return a1
}
