import { put, call, takeLatest, all } from 'redux-saga/effects'
import { Types } from '../actions/claim'
import { requestCreator, successCreator, failureCreator } from 'utils/actions'
import { notification } from 'antd'
import { app } from 'configs'

import * as services from 'apis/claim'

function * getClaims (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.getClaims, action.limit, {
      status: action.status,
      sortby: action.sortBy,
      LastEvaluatedKey: action.lastKey
    })
    yield put(
      successCreator(action.type, {
        status:
          action.status === 'approved-denied'
            ? 'approved_denied'
            : action.status,
        ...res
      })
    )
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * updateClaim (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.updateClaim, action.id, {
      status: action.status,
      reason: action.reason
    })
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * generateInvoices (action) {
  try {
    yield put(requestCreator(action.type))
    yield call(services.generateInvoices, action.payload)
    yield put(successCreator(action.type, { ids: action.payload.claimids }))

    if (app.showNotificationInSaga) {
      notification.open({
        message: 'Generating Invoices...',
        description: 'Started Generating Invoices',
        duration: 3
      })
    }
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

function * getInvoices (action) {
  try {
    yield put(requestCreator(action.type))
    const res = yield call(services.getInvoices, action.next)
    yield put(successCreator(action.type, res))
  } catch (err) {
    yield put(failureCreator(action.type, { err }))
  }
}

export default function * sagas () {
  yield all([
    takeLatest(Types.GET_CLAIMS, getClaims),
    takeLatest(Types.UPDATE_CLAIM, updateClaim),
    takeLatest(Types.GENERATE_INVOICES, generateInvoices),
    takeLatest(Types.GET_INVOICES, getInvoices)
  ])
}
