import invokeApi from 'utils/invokeApi'
import { api } from 'configs'

export const getUsers = () => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.users.list,
    method: 'get',
    auth: true
  })
}

export const enableUser = payload => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.users.enable,
    body: payload,
    method: 'put',
    auth: true
  })
}

export const disableUser = payload => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.users.disable,
    body: payload,
    method: 'put',
    auth: true
  })
}

export const dismissUser = payload => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.users.dismiss,
    body: payload,
    method: 'put',
    auth: true
  })
}

export const createUser = payload => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.users.create,
    body: payload,
    method: 'post',
    auth: true
  })
}
