import App from './App'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { Creators } from 'store/actions/auth'
import { Creators as GlobalCreators } from 'store/actions/global'

const mapStateToProps = state => ({
  auth: state.auth,
  global: state.global
})

const mapDispatchToProps = {
  changeRoute: push,
  getCurrentUser: Creators.getCurrentUser,
  setDisplayMode: GlobalCreators.setDisplayMode
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
