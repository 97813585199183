import invokeApi from 'utils/invokeApi'
import { api } from 'configs'

export const getAnalytics = () => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.analytics.get,
    method: 'get',
    auth: true
  })
}
