import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    resetClaims: ['status'],
    resetInvoices: null,
    getClaims: ['status', 'limit', 'lastKey', 'sortBy'],
    updateClaim: ['id', 'status', 'reason'],
    generateInvoices: ['payload'],
    getInvoices: ['next']
  },
  { prefix: 'claim/' }
)

export { Types, Creators }
