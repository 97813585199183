import { createReducer } from 'reduxsauce'
import { Types } from '../actions/product'
import { request, success } from 'utils/actions'

// Initial State
const initialState = {
  items: [],
  lastKey: null
}

const getProductsSuccess = (state, action) => {
  return {
    ...state,
    items: [...state.items, ...action.payload.Items],
    lastKey: action.payload.LastEvaluatedKey
  }
}

export const createProductSuccess = (state, action) => {
  return { ...state, items: [action.payload.new, ...state.items] }
}

export const updateProductSuccess = (state, action) => {
  return {
    ...state,
    items: state.items.map(item => {
      if (item.id === action.payload.id) {
        return {
          ...item,
          ...action.payload.new
        }
      } else {
        return item
      }
    })
  }
}

const deleteProductSuccess = (state, action) => {
  return {
    ...state,
    items: state.items.filter(item => item.id !== action.payload.id)
  }
}

const handlers = {
  [success(Types.CREATE_PRODUCT)]: createProductSuccess,
  [success(Types.UPDATE_PRODUCT)]: updateProductSuccess,
  [success(Types.GET_PRODUCTS)]: getProductsSuccess,
  [request(Types.DELETE_PRODUCT)]: deleteProductSuccess
  // [success(Types.DELETE_PRODUCT)]: deleteProductSuccess,
}

// Export Reducer
export default createReducer(initialState, handlers)
