import invokeApi from 'utils/invokeApi'
import { api } from 'configs'

export const getSalespeople = (limit, payload) => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.salespeople.list,
    method: 'post',
    body: payload,
    queries: { limit },
    auth: true
  })
}

export const createSalesperson = payload => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.salespeople.create,
    method: 'post',
    body: payload,
    auth: true
  })
}

export const updateSalesperson = (id, payload) => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.salespeople.update,
    method: 'put',
    body: payload,
    paths: { id },
    auth: true
  })
}

export const deleteSalesperson = id => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.salespeople.delete,
    method: 'delete',
    paths: { id },
    auth: true
  })
}

export const downloadSalespeople = () => {
  return invokeApi({
    baseUrl: api.base,
    endpoint: api.salespeople.download,
    method: 'get',
    auth: true
  })
}
