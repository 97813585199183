import { createReducer } from 'reduxsauce'
import { Types } from '../actions/analytics'
import { success } from 'utils/actions'

export const initialState = {}

const getAnalyticsSuccess = (state, action) => {
  return {
    ...state,
    ...action.payload
  }
}

const handlers = {
  [success(Types.GET_ANALYTICS)]: getAnalyticsSuccess
}

export default createReducer(initialState, handlers)
